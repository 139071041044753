import { render, staticRenderFns } from "./HonourCertificate.vue?vue&type=template&id=d0044634&scoped=true&"
import script from "./HonourCertificate.vue?vue&type=script&lang=js&"
export * from "./HonourCertificate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0044634",
  null
  
)

export default component.exports
// 荣誉证书
<template>
  <div class="honour-honourCertificate">
    <InfoImg />
  </div>
</template>

<script>
import InfoImg from '@/components/InfoImg'
export default {
  components: {
    InfoImg
  },
};
</script>

<style scoped lang="less">
</style>
